<template>
	<div class="stacked-bar-chart tw-w-full tw-h-[1000px] tw-mt-4 tw-bg-white tw-relative">
		<div
		v-for="i in 20"
		:key="'line-' + i"
		class="tw-w-full tw-h-[50px] tw-relative"
		>
			<div
			v-if="i > 0"
			class="tw-border-t tw-border-gray-300 tw-absolute tw-top-0 tw-w-full"
			/>

			<span class="tw-text-gray-600 tw-absolute tw-left-[150px] tw-top-0">
				{{ (i-1) * 50 }} mm
			</span>
			<span class="tw-text-gray-600 tw-absolute tw-right-2 tw-top-0">
				{{ (i-1) * 50 }} mm
			</span>
		</div>

		<div
		class="tw-flex tw-absolute tw-bottom-0 tw-w-full tw-h-full tw-flex-row"
		:style="{ transform: `translateX(${(gapCore / 2) + 190}px)`, gap: `${gapCore - 15}px` }"
		>
			<div
			v-for="(core, index) in cores"
			:key="'core-' + index"
			class="tw-flex tw-flex-col tw-items-center tw-mx-2"
			>
				<div
				v-for="(layer, layerIndex) in core.layersAndInterfaces.filter(layer => layer.type === 'layer')"
				:key="'layer-' + index + '-' + layerIndex"
				:style="{
					height: `${layer.thickness}px`,
					backgroundColor: layer.layerName.color ? layer.layerName.color : '#ccc'
				}"
				class="tw-w-[100px] tw-border-[1px] tw-border-solid tw-border-[#2C0703]"
				>
					&nbsp;
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "StackedBarChart",
	props: {
		cores: {
			type: Array,
			required: true
		}
	},
	data(){
		return {
			gapCore: 0
		};
	},
	watch: {
		cores(){
			this.calculateDisplay();
		}
	},
	methods: {
		calculateDisplay(){
			let totalWidth = document.querySelector(".stacked-bar-chart").clientWidth - 200;
			let widthPerBar = (totalWidth / this.cores.length);
			this.gapCore = widthPerBar - 100;
		}
	},
	mounted(){
		this.calculateDisplay();
		window.addEventListener("resize", this.calculateDisplay);
	},
	beforeDestroy(){
		window.removeEventListener("resize", this.calculateDisplay);
	}
};
</script>

<style scoped>
.stacked-bar-chart {
	background-color: white;
	border-radius: 4px;
	border: 1px solid #ccc;
}

.stacked-bar-chart .tw-border-t {
	height: 1px;
	background-color: #ccc;
}

.stacked-bar-chart .tw-text-gray-600 {
	font-size: 12px;
}

.tw-border-gray-300 {
	border-color: #ccc;
}
</style>
