var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showCore === true)?_c('div',{staticClass:"tw-flex tw-flex-col",class:{
	'select': _vm.selectedCore.id === _vm.core.id,
	'rmMarginTop': _vm.filter === 'asbestosDuplicated' || _vm.filter === 'pahDuplicated',
	'tw-w-[500px]': _vm.filter === 'asbestosDuplicated' || _vm.filter === 'pahDuplicated',
	'tw-mr-[10px]': _vm.filter !== 'asbestosDuplicated' && _vm.filter !== 'pahDuplicated'
},staticStyle:{"border":"none !important"},style:(!_vm.filter ? { '--bg-color': _vm.color, 'color': _vm.textColor, 'margin-left': '10px' } : { 'margin-right': '0px'}),attrs:{"coring-map-marker":""},on:{"click":_vm.clicked}},[(_vm.filter === 'asbestosDuplicated' || _vm.filter === 'pahDuplicated')?_c('span',{staticClass:"coreName"},[_vm._v(" "+_vm._s(_vm.core.displayNamePrefix[0] ? ( _vm.core.displayNamePrefix[0].name + _vm.core.displayName === _vm.core.businessId ? _vm.core.businessId.substr(_vm.core.businessId.length - 6) : _vm.core.displayNamePrefix[0].name + _vm.core.displayName ) : _vm.core.businessId.substr(_vm.core.businessId.length - 6))+" ")]):_vm._e(),(_vm.filter)?_c('img',{staticStyle:{"width":"30px","height":"30px"},attrs:{"src":_vm.getBackgroundImage()}}):_vm._e(),_c('span',{staticClass:"centered",style:(_vm.filter === 'allResult' || _vm.filter.includes('Duplicated') ? 
		'top: 110%;' : 
		'top: 47%;')},[_vm._v(" "+_vm._s(_vm.core.number)+" ")])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }