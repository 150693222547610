<template>
	<tbody>
		<tr>
			<td
			:rowspan="layersByCores + 2"
			style="width: 30px !important"
			>
				<span
				class="font-weight-bold"
				style="font-size: 24px; width: 40px"
				>
					VR ou sens 2
				</span>
			</td>
		</tr>
		<tr v-if="sortOrder === 'PR + Abscisse'">
			<td style="border-right: 2px solid black; font-size: 16px">
				N° Carotte
			</td>
			<td
			v-for="(header, key) in headers"
			colspan="5"
			class="text-center font-weight-bold coreName tw-max-w-[275px]"
			>
				{{ vrCores.find(core => core.landmark+'+'+core.abscissa === header) ? vrCores.find(core => core.landmark+'+'+core.abscissa === header).businessId.slice(-6) : '' }}
			</td>
		</tr>
		<tr
		v-for="n in layersByCores"
		:key="n+'VR'"
		>
			<td 
			style="border-right: 2px solid black;font-size: 16px"
			class="tw-w-[56px] tw-min-w-[56px]"
			>
				Couche {{ n }}
			</td>
			<template v-for="(header, key) in headers">
				<template 
				v-if="sortOrder === 'PR + Abscisse' ? 
					vrCores.find(core => core.landmark+'+'+core.abscissa === header) ?
						vrCores.find(core => core.landmark+'+'+core.abscissa === header).status !== 'cancelled' : true : 
					vrCores.find(core => core.businessId.slice(-3) === header) ?
						vrCores.find(core => core.businessId.slice(-3) === header).status !== 'cancelled' : true"
				>
					<td
					class="text-center tw-w-[56px] tw-min-w-[56px]"
					style="border-left: 2px solid black; font-size: 20px"
					:style="getStyleBySortOrder(header, n)"
					>
						{{ getValue(header,n, 'cumul') }}
					</td>
					<td
					class="text-center tw-w-[56px] tw-min-w-[56px]"
					style="border-left: 2px solid black; font-size: 20px"
					:style="'background-color:'+isGreyBackground(header, 'VR', n)"
					>
						{{ getMaterial(header, 'VR', n) }}
					</td>
					<td
					class="text-center tw-w-[56px] tw-min-w-[56px]"
					:style="getStyleByType(header, n, 'Asbestos')"
					/>
					<td
					class="text-center tw-w-[56px] tw-min-w-[56px]"
					:style="getStyleByType(header, n, 'PAH')"
					/>
					<td
					class="text-center tw-w-[56px] tw-min-w-[56px]"
					style="border-right: 2px solid black;"
					:style="getStyleBySortOrder(header, n)"
					>
						{{ getValue(header,n,'TH') }}
					</td>
				</template>
				<template v-else>
					<td
					v-if="key === 0 && n === 1"
					:rowspan="headers[key].length + 1"
					colspan="5"
					style="background-color: lightsalmon"
					>
						{{ 
							sortOrder === 'PR + Abscisse' ? 
								vrCores.find(core => core.landmark+'+'+core.abscissa === header).note :
								vrCores.find(core => core.businessId.slice(-3) === header) ?
									vrCores.find(core => core.businessId.slice(-3) === header).note :
									''
						}}
					</td>
				</template>
			</template>
		</tr>
	</tbody>
</template>

<script>
export default {
	props: {
		isGreyBackground: {
			required: true,
			type: Function
		},
		getCumul: {
			required: true,
			type: Function
		},
		getAsbestos: {
			required: true,
			type: Function
		},
		getPAH: {
			required: true,
			type: Function
		},
		getTH: {
			required: true,
			type: Function
		},
		getMaterial: {
			required: true,
			type: Function
		},
		layersByCores: {
			required: true,
			type: Number
		},
		vrCores: {
			required: true,
			type: Array
		},
		headers: {
			required: true,
			Type: Array
		},
		sortOrder: {
			required: true,
			Type: Boolean
		}
	},
	data(){
		return {
		};
	},
	watch: {
		sortOrder(){
			this.vrCores.forEach(core => {
				console.log(core.businessId);
			});

			console.log(this.vrCores.length);
		}
	},
	methods: {
		getStyleByType(header, n, type = null){
			let functionType;
			if(type === "PAH"){
				functionType = this.getPAH;
			}
			else if(type === "TH"){
				functionType = this.getTH;
			}
			else {
				functionType = this.getAsbestos;
			}

			let funcResp = functionType(header, "VR", n);

			if(this.sortOrder === "N° Carotte"){
				if(funcResp === "transparent"){
					return "background-color:" + this.isGreyBackground(header, "VR", n);
				}
				else {
					return "background-color:" + funcResp;
				}

			}
			else {
				if(this.vrCores.find(core => core.landmark + "+" + core.abscissa === header)){
					if(funcResp === "transparent"){
						return "background-color:" + this.isGreyBackground(header, "VR", n);
					}
					else {
						return "background-color:" + funcResp;
					}
				}
				else {
					return "transparent";
				}
			}
		},
		getStyleBySortOrder(header, n){
			if(this.sortOrder === "N° Carotte"){
				if(this.vrCores.find(core => core.displayName === header)){
					return "background-color:" + this.isGreyBackground(header, "VR", n);
				}
				return "";
			}
			else {
				if(this.vrCores.find(core => core.landmark + "+" + core.abscissa === header)){
					return "background-color:" + this.isGreyBackground(header, "VR", n);
				}
				return "";
			}
		},
		getValue(header, n, type){
			let functionType;
			if(type === "cumul"){
				functionType = this.getCumul;
			}
			else {
				functionType = this.getTH;
			}

			if(this.vrCores.find(core => core.landmark + "+" + core.abscissa === header)){
				return functionType(header, "VR", n);
			}
			else {
				if(this.vrCores.find(core => core.displayName === header)){
					return functionType(header, "VR", n);
				}
				
			}
			return "";
		}
	}
};
</script>

<style lang="scss" scoped>
th span 
{
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
}

td span 
{
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
}


.bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
}
td {
  border: solid 1px lightgrey;
  min-width: 50px;
}
th {
  background-color: #2b0803;
  color: white !important;
  font-size: 24px;
  border: 1px solid white
}
tr > td:first-child {
  background-color: #ebe7e7;
  font-weight: bold;
}</style>
