<template>
	<div
	v-if="showCore === true"
	@click="clicked"
	style="border: none !important"
	:style="!filter ? { '--bg-color': color, 'color': textColor, 'margin-left': '10px' } : { 'margin-right': '0px'}"
	class="tw-flex tw-flex-col"
	:class="{
		'select': selectedCore.id === core.id,
		'rmMarginTop': filter === 'asbestosDuplicated' || filter === 'pahDuplicated',
		'tw-w-[500px]': filter === 'asbestosDuplicated' || filter === 'pahDuplicated',
		'tw-mr-[10px]': filter !== 'asbestosDuplicated' && filter !== 'pahDuplicated'
	}"
	coring-map-marker
	>
		<span
		v-if="filter === 'asbestosDuplicated' || filter === 'pahDuplicated'"
		class="coreName"
		>
			{{ core.displayNamePrefix[0] ?
				(
					core.displayNamePrefix[0].name + core.displayName === core.businessId ?
						core.businessId.substr(core.businessId.length - 6) : 
						core.displayNamePrefix[0].name + core.displayName 
				) : 
				core.businessId.substr(core.businessId.length - 6) }}
		</span>
		<img
		v-if="filter"
		:src="getBackgroundImage()"
		style="width: 30px; height: 30px;"
		/>
		<span
		class="centered"
		:style="filter === 'allResult' || filter.includes('Duplicated') ? 
			'top: 110%;' : 
			'top: 47%;'"
		>
			{{ core.number }}
		</span>
	</div>
</template>

<script>
import Vue from "vue";
import {mapGetters, mapActions} from "vuex";
import mapboxgl from "mapbox-gl";
import popupUtils from "../../../utils/map/popup.utils";
import CoringMacroPopup from "./CoringMacroPopup.vue";

export default Vue.extend({
	name: "CoringMapMarker",
	components: {
		
	},
	props: {
		core: {
			default: false
		},
		router: {
			required: true,
			type: Object
		}
	},
	data(){
		return {
			clickCount: 0,
			popup: false
		};
	},
	computed: {
		...mapGetters("coringMacro", [
			"map", "filter", "filterValue", "selectedCore", "filterCampaign", "selectedOrganization"
		]),

		...mapGetters("coring", ["filterByValues"]),

		textColor(){
			let textColor;
			this.color === "#D8D8D8" ? textColor = "black" : textColor = "white";
			return textColor;
		},

		border(){
			let border;
			this.color === "#D8D8D8" ? border = "solid black 2px" : border = null;
			return border;
		},

		color(){
			if(!this.filter) return "green";
			else if(this.filter === "PAH" || this.filter === "pahDuplicated"){
				if(this.core.sumPAH >= 1000) return "black";
				else if(this.core.sumPAH >= 500 && this.core.sumPAH < 1000) return "red";
				else if(this.core.sumPAH >= 50 && this.core.sumPAH < 500) return "orange";
				else if(this.core.sumPAH < 50) return "green";
			}
			else if(this.filter === "asbestos" || this.filter === "asbestosDuplicated"){
				if(this.core.containsAsbestos === true) return "red";
				else return "green";
			}
			else if(this.filter === "TH"){
				if(this.core.sumTH >= 500) return "orange";
				else if(this.core.sumTH < 500) return "green";
			}
			else if(this.filter === "allResults"){
				if(this.core.status === "cancelled") return "#2B0803";
				return null;
			}
		},

		sum(){
			let sum = null;
			for(const layer of this.core.layersAndInterfaces){
				if(this.filter === "PAH" || this.filter === "pahDuplicated"){
					if(layer.type !== "layer") continue;
					if((sum === null || sum < layer.PAHResult?.sum) && layer.PAHResult?.sum !== undefined && layer.PAHResult !== null) sum = layer.PAHResult?.sum ?? null;
				}
				else if(this.filter === "TH"){
					if(layer.type !== "layer") continue;
					if((sum === null || sum < layer.THResult?.sum) && layer.THResult?.sum !== undefined && layer.THResult !== null) sum = layer.THResult?.sum ?? null;
				}
			}
			return sum;
		},

		asbestos(){
			let containsAsbestos = null;
			for(const layer of this.core.layersAndInterfaces){
				if(layer.asbestosResult?.containsAsbestos === true) containsAsbestos = true;
				if(layer.asbestosResult?.containsAsbestos === false && containsAsbestos !== true) containsAsbestos = false;
			}
			return containsAsbestos;
		},

		showCore(){
			if(this.filter === "PAH" || this.filter === "pahDuplicated"){
				if(this.core.sumPAH >= 1000 && this.filterValue.PAH.indexOf(1000) === -1) return false;
				else if(this.core.sumPAH >= 500 && this.core.sumPAH < 1000 && this.filterValue.PAH.indexOf(500) === -1) return false;
				else if(this.core.sumPAH >= 50 && this.core.sumPAH < 500 && this.filterValue.PAH.indexOf(50) === -1) return false;
				else if(this.core.sumPAH < 50 && this.core.sumPAH !== null && this.filterValue.PAH.indexOf(0) === -1) return false;
				else if(this.core.sumPAH === null && this.filterValue.PAH.indexOf(null) === -1) return false;
				else if(!this.newFilter()) return false;
				else return true;
			}
			else if(this.filter === "asbestos" || this.filter === "asbestosDuplicated"){
				if(this.asbestos === true && this.filterValue.asbestos.indexOf(true) === -1) return false;
				else if(this.asbestos === null && this.filterValue.asbestos.indexOf("waiting") === -1 && this.core.status === "waiting") return false;
				else if(this.asbestos === null && this.filterValue.asbestos.indexOf("cancelled") === -1 && this.core.status === "cancelled") return false;
				else if(this.asbestos === null && this.filterValue.asbestos.indexOf("empty") === -1 && this.core.status === "empty") return false;
				else if(this.asbestos === null && this.filterValue.asbestos.indexOf(null) === -1 && this.core.status !== "empty" && this.core.status !== "waiting" && this.core.status !== "cancelled") return false;
				else if(this.asbestos === false && this.filterValue.asbestos.indexOf(false) === -1) return false;
				else if(!this.newFilter()) return false;
				else return true;
			}
			else if(this.filter === "TH"){
				if(this.sumTH >= 500 && this.filterValue.TH.indexOf(500) === -1) return false;
				else if(this.sumTH < 500 && this.filterValue.TH.indexOf(0) === -1) return false;
				else if(this.sumTH === null && this.filterValue.TH.indexOf(null) === -1) return false;
				else if(!this.newFilter()) return false;
				else return true;
			}
			else if(this.filter === "allResults"){
				if(this.core.sumPAH >= 1000 && this.filterValue.PAH.indexOf(1000) === -1 && this.core.sumPAH !== null) return false;
				else if(this.core.sumPAH >= 500 && this.core.sumPAH < 1000 && this.filterValue.PAH.indexOf(500) === -1 && this.core.sumPAH !== null) return false;
				else if(this.core.sumPAH >= 50 && this.core.sumPAH < 500 && this.filterValue.PAH.indexOf(50) === -1 && this.core.sumPAH !== null) return false;
				else if(this.core.sumPAH < 50 && this.filterValue.PAH.indexOf(0) === -1 && this.core.sumPAH !== null) return false;
				
				else if(this.asbestos === true && this.filterValue.asbestos.indexOf(true) === -1) return false;
				else if(this.asbestos === false && this.filterValue.asbestos.indexOf(false) === -1) return false;
				else if(this.sumTH >= 500 && this.filterValue.TH.indexOf(500) === -1 && this.sumTH !== null) return false;
				else if(this.sumTH < 500 && this.filterValue.TH.indexOf(0) === -1 && this.sumTH !== null) return false;
				else if(!this.newFilter()) return false;
				else return true;
			}
			else {
				if(!this.newFilter()) return false;
				return true;
			}

		}
	},
	methods: {
		...mapActions("coringMacro", ["selectCore"]),

		clicked(){
			this.selectCore(this.core);
			this.displayPopup();
		},

		newFilter(){
			let display = true;
			this.filterByValues.forEach(filter => {
				if(filter.type === "road"){
					if(!filter.value.includes(this.core.road.name)){
						display = false;
					}
				}
				else if(filter.type === "business"){
					if(this.core.displayNamePrefix !== null && this.core.displayName !== null){
						if(!filter.value.includes(this.core.displayNamePrefix.name + this.core.displayName)){
							display = false;
						}
					}
					else {
						if(!filter.value.includes(this.core.businessId)){
							display = false;
						}
					}
				}
				else if(!filter.value.includes(this.core[filter.type])){
					display = false;
				}
			});
			return display;
		},


		async displayPopup(){
			let data = await this.$api.cores.get(this.core.id);

			this.popup = popupUtils.generatePopup(
				this.selectedCore.lng,
				this.selectedCore.lat,
				data,
				this.map,
				CoringMacroPopup,
				() => {
					this.popup = null;
				},
				this.router
			);
		},

		getBackgroundImage(){
			let imageName;
			let checkNumber = 0;
			let sumAsbestos = 0;
			let sumPAH = 0;
			let sumTH = 0;
			if(this.filter === "allResults"){
				this.asbestos !== null ? checkNumber += 1 : "";
				this.core.sumTH !== null ? checkNumber += 1 : "";
				this.core.suPAH !== null ? checkNumber += 1 : "";
			}
			else {
				checkNumber = 1;
			}
			
			switch (checkNumber){
				case 1: 
					imageName = "circle";
					break;
				case 2: 
					imageName = "half_circle";
					break;
				case 3: 
					imageName = "third_circle";
					break;
			}

			if(this.filter === "allResults"){

				if(checkNumber === 1){
					if(this.asbestos === true){
						imageName += "_A";
					}
					else if(this.core.sumTH !== null){
						imageName += "_HCT";
					}
					else if(this.core.sumPAH !== null){
						imageName += "_HAP";
					}
				}
			}
			else {
				if(checkNumber === 1){
					if(this.filter === "asbestos" || this.filter === "asbestosDuplicated"){
						imageName += "_A";
					}
					else if(this.filter === "TH"){
						imageName += "_HCT";
					}
					else if(this.filter === "PAH" || this.filter === "pahDuplicated"){
						imageName += "_HAP";
					}
						
				}
			}

			if(checkNumber === 2){
				if(this.asbestos !== null && this.core.sumPAH !== null){
					imageName += "_AHAP";
				}
				else if(this.asbestos !== null && this.core.sumTH !== null){
					imageName += "_AHCT";
				}
				else if(this.core.sumPAH !== null && this.core.sumTH !== null){
					imageName += "_HAPHCT";
				}
					
			}
			
			for(const layer of this.core.layersAndInterfaces){
				if(layer.type !== "layer") continue;
				if(this.asbestos !== null){
					if(layer.asbestosResult?.containsAsbestos === true){
						sumAsbestos = 2;
					}
					else if(layer.asbestosResult?.containsAsbestos === false &&  sumAsbestos <= 1){
						sumAsbestos = 1;
					}
				}
				if(this.core.sumPAH){
					if(layer.PAHResult?.sum >= 1000){
						sumPAH = 4;
					}
					else if(layer.PAHResult?.sum >= 500 && sumPAH <= 3){
						sumPAH = 3;
					}
					else if(layer.PAHResult?.sum >= 50 && sumPAH <= 2){
						sumPAH = 2;
					}
					else if(layer.PAHResult?.sum < 50 && sumPAH <= 1){
						sumPAH = 1;
					}
				}
				if(this.core.sumTH){
					if(layer.THResult?.sum >= 500 && sumTH <= 2){
						sumTH = 2;
					}
					else if(layer.THResult?.sum < 500 && sumTH <= 1){
						sumTH = 1;
					}
				}
					
			}
			if(this.filter === "allResults"){
				if(checkNumber === 1){
					if(this.asbestos !== null){
						imageName += `${"_" + sumAsbestos}`;
					}
					else if(this.core.sumTH !== null){
						
						imageName += `${"_" + sumTH}`;
					}
					else if(this.core.sumPAH !== null){
						
						imageName += `${"_" + sumPAH}`;
					}
						
				}
			}
			else {
				if(checkNumber === 1){
					if(this.filter === "asbestos" || this.filter === "asbestosDuplicated"){
						imageName += `${"_" + sumAsbestos}`;
					}
					else if(this.filter === "TH"){
						
						imageName += `${"_" + sumTH}`;
					}
					else if(this.filter === "PAH" || this.filter === "pahDuplicated"){
						
						imageName += `${"_" + sumPAH}`;
					}
						
				}
			}
			if(checkNumber === 2){
				if(this.asbestos !== null && this.core.sumPAH !== null){
					imageName += `${"_" + sumAsbestos}${"_" + sumPAH}`;
				}
				else if(this.asbestos !== null && this.core.sumTH !== null){
					
					imageName += `${"_" + sumAsbestos}${"_" + sumTH}`;
				}
				else if(this.core.sumPAH !== null && this.core.sumTH !== null){
					
					imageName += `${"_" + sumPAH}${"_" + sumTH}`;
				}
					
			}
			else if(checkNumber === 3){
				imageName += `${"_" + sumAsbestos}${"_" + sumPAH}${"_" + sumTH}`;
			}

			return require(`@/assets/images/coring/Markers/${imageName}.png`);
		}
	},
});
</script>

<style lang="scss">
.coreName {
	color: black !important;
	background-color: white !important;
	padding: 5px !important;
	border-radius: 8px !important;
}
.centered {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

div[coring-map-marker]{
    color: white;
    background: var(--bg-color);
    aspect-ratio: 1/1;
    height: 20px;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    cursor: pointer;

    &.select{
        border: solid black 2px;
    }
}
</style>
