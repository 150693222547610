import { render, staticRenderFns } from "./SynthesisTable.bau.vue?vue&type=template&id=4ceae3f9&scoped=true&"
import script from "./SynthesisTable.bau.vue?vue&type=script&lang=js&"
export * from "./SynthesisTable.bau.vue?vue&type=script&lang=js&"
import style0 from "./SynthesisTable.bau.vue?vue&type=style&index=0&id=4ceae3f9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ceae3f9",
  null
  
)

export default component.exports