<template>
	<div class="tw-w-[200px] tw-flex tw-flex-col tw-gap-[10px] tw-max-h-[500px]">
		<div class="tw-w-full tw-flex tw-flex-col">
			<h3>
				{{
					features.displayName !== null && features.displayNamePrefix !== null ?
						features.displayNamePrefix.name + features.displayName :
						"Carotte : " + features.number
				}}
			</h3>

			<div class="tw-w-full tw-flex tw-flex-col">
				<tr
				v-if="features.customerId !== null"
				class="tw-w-full tw-flex tw-justify-between"
				>
					<td><span>Id client : </span></td>

					<td class="tw-ml-[10px]">
						<span>{{ features.customerId }}</span>
					</td>
				</tr>

				<tr
				v-if="extractedDate"
				class="tw-w-full tw-flex tw-justify-between"
				>
					<td><span>Date du prélèvement : </span></td>

					<td class="tw-ml-[10px]">
						<span>{{ moment(extractedDate.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
					</td>
				</tr>

				<tr
				v-if="cancelledDate"
				class="tw-w-full tw-flex tw-justify-between"
				>
					<td><span>Date de l'annulation:</span></td>

					<td>
						<span>{{ cancelledDate.createdAt.split("T")[0].split("-").reverse().join("/") }}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Route :</span></td>

					<td>
						<span>{{ features.road.name }}</span>
					</td>
				</tr>

				<tr
				class="tw-w-full tw-flex tw-justify-between"
				v-if="features.city"
				>
					<td><span>Commune :</span></td>

					<td>
						<span>{{ features.city.name }}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Sens :</span></td>

					<td>
						<span>{{ features.direction === 3 ? 'Autre' : features.direction }}</span>
					</td>
				</tr>

				<tr
				class="tw-w-full tw-flex tw-justify-between"
				>
					<td><span>Localisation :</span></td>

					<td>
						<span>{{ features.lane }}</span>
					</td>
				</tr>

				<tr
				class="tw-w-full tw-flex tw-justify-between"
				v-if="features.landmark && features.abscissa"
				>
					<td><span>PR + ABS : </span></td>

					<td>
						<span>
							{{ features.landmark ? features.landmark : "" }}
							{{
								features.landmark && features.abscissa
									? "+" + features.abscissa
									: ""
							}}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Epaisseur : </span></td>

					<td>
						<span> {{ depth }} mm</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Diamètre : </span></td>

					<td>
						<span> {{ features.diameter }} mm</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Latéralisation : </span></td>

					<td>
						<span> {{ features.lateralPosition }}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between tw-gap-[10px]">
					<td><span>Type de structure : </span></td>

					<td>
						<span> {{ features.structureType }}</span>
					</td>
				</tr>

				<tr class="tw-w-full tw-flex tw-justify-between">
					<td><span>Structure complète : </span></td>

					<td>
						<span> {{ features.isFullStructure ? "Oui" : "Non" }}</span>
					</td>
				</tr>
			</div>
			
			<div class="tw-w-full tw-h-[1px] tw-bg-[rgba(68,68,68,0.562)]"/>

			<h3>
				Matériaux
			</h3>

			<table class="tw-w-full tw-flex tw-flex-col tw-max-h-[100px] tw-overflow-y-auto">
				<div
				class="tw-w-full tw-flex tw-flex-col"
				v-if="layers !== false"
				v-for="layer in layers"
				:key="layer.id"
				>
					<h3>
						Couche {{ layer.number }}
					</h3>
					<tr class="tw-w-full tw-flex tw-justify-between">
						<td>
							<span>Epaisseur :</span>
						</td>

						<td>
							<span>{{ layer.thickness }} mm</span>
						</td>
					</tr>
					<tr class="tw-w-full tw-flex tw-justify-between">
						<td>
							<span>Matériaux :</span>
						</td>

						<td>
							<span>{{ fr[layer.materialCategory.name] }}
								{{ layer.material? " - " + layer.material.name : "" }}</span>
						</td>
					</tr>
					<tr class="tw-w-full tw-flex tw-justify-between">
						<td>
							<span>Granulométrie :</span>
						</td>

						<td>
							<span>{{ layer.granulometry !== null ? layer.granulometry.name : "" }}</span>
						</td>
					</tr>
					<tr class="tw-w-full tw-flex tw-justify-between">
						<td>
							<span>Etat :</span>
						</td>
						<td>
							<span>{{ fr[layer.state] }}</span>
						</td>
					</tr>
					<tr
					class="tw-w-full tw-flex tw-justify-between"
					v-if="layer.asbestosResult !== null"
					>
						<td>
							<span>Date d'envoi :</span>
						</td>
						<td>
							<span>{{ moment(layer.asbestosResult.sendDate).format("DD/MM/YY") }}</span>
						</td>
					</tr>
					<tr
					class="tw-w-full tw-flex tw-justify-between"
					v-if="layer.asbestosResult !== null"
					>
						<td>
							<span>Date de résultat :</span>
						</td>
						<td>
							<span>{{ layer.asbestosResult.receivedDate }}</span>
						</td>
					</tr>

					<tr
					class="tw-w-full tw-flex tw-justify-between"
					v-if="layer.year"
					>
						<td>
							<span>Année des matériaux :</span>
						</td>

						<td>
							<span>{{ layer.year }}</span>
						</td>
					</tr>
					<tr
					class="tw-w-full tw-flex tw-justify-between"
					v-if="layer.asbestosResult"
					>
						<td>
							<span>Résultat amiante :</span>
						</td>

						<td>
							<span>{{ layer.asbestosResult.containsAsbestos === null ? 'Non analysé' : layer.asbestosResult.containsAsbestos === true ? 'Détecté' : 'Non détecté' }}</span>
						</td>
					</tr>

					<tr
					class="tw-w-full tw-flex tw-justify-between"
					v-if="layer.PAHResult"
					>
						<td>
							<span>Résultat HAP :</span>
						</td>

						<td>
							<span>{{ layer.PAHResult.sum }} mg/kg</span>
						</td>
					</tr>

					<tr
					class="tw-w-full tw-flex tw-justify-between"
					v-if="layer.THResult"
					>
						<td>
							<span>Résultat HAP :</span>
						</td>

						<td>
							<span>{{ layer.THResult.sum }} mg/kg</span>
						</td>
					</tr>
				</div>
			</table>
		</div>

		<div class="tw-flex tw-flex-col tw-items-center tw-gap-[10px]">
			<ButtonSlot
			_theme="light-gray"
			@click="router.push('/coring-campaign/' + features.coringCampaign.id+'/dashboard')"
			_small
			>
				campagne
			</ButtonSlot>

			<ButtonSlot
			@click="downloadPDF"
			_small
			>
				Rapport PDF
			</ButtonSlot>

			<ButtonSlot
			_theme="light-gray"
			@click="closeFunc"
			_small
			>
				fermer
			</ButtonSlot>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import fr from "@/locales/fr";
import {mapActions, mapGetters, mapMutations,} from "vuex";

export default Vue.extend({
	props: {
		features: {
			required: true,
			type: Object
		},
		closeFunc: {
			required: true,
			type: Function
		},
		router: {
			required: true,
			type: Object
		}
	},
	data(){
		return {
			layers: this.features.layersAndInterfaces.filter(value => value.type === "layer"),
			fr: fr
		};
	},
	computed: {
		depth(){
			return this.layers.reduce((v, l) => v += l.thickness, 0);
		},
		extractedDate(){
			return this.features.statusChange ? this.features.statusChange.find(v => v.status === "extracted") : false;
		},
		cancelledDate(){
			return this.features.statusChange ? this.features.statusChange.find(v => v.status === "cancelled") : false;
		}

	},
	methods: {
		...mapMutations("coringMacro", []),
		...mapActions("coringMacro", ["unSelectCore"]),

		async downloadPDF(){
			const blob = await this.$api.cores.getPdf(this.features.id);
			const fileName =
        	"core-" + this.features.fullDisplayNameAsString
        		? this.features.fullDisplayNameAsString
        		: this.features.businessId;
			
			var newBlob = new Blob([blob], {type: "application/pdf"});
			const data = window.URL.createObjectURL(newBlob);
			var link = document.createElement("a");
			link.href = data;
			link.download = fileName;
			window.open(link, "_blank");
		},
		close(){
			this.$destroy();
			this.closeFunc();
		}
	},
	mounted(){
	},
	destroyed(){
		this.closeFunc();
	}
});
</script>

<style lang="scss">

</style>
