<template>
	<div class="coring_dashboard tw-p-[24px]">
		<HeaderSlot
		:title="campaign.name"
		class="tw-mb-[10px]"
		@clickBackButton="$router.back()"
		>
			<MediumButtonSlot @click="exportCsv()">
				Exportation CSV
			</MediumButtonSlot>

			<ButtonSlot
			:_to="{
				name: 'structural-coring-campaign-map',
				params: { id: campaign.id }
			}"
			_icon="mdi-map-outline"
			>
				Accéder à la carte
			</ButtonSlot>
		</HeaderSlot>

		<!--<v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">Voie</th>
            <th
              v-for="(header, key) in dashboard.headers"
              :key="key"
              :colspan="header.count"
              class="text-center font-weight-bold"
              style="border-right: 1px solid black"
            >
              {{ header.lane }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(values, key) in dashboard.values" :key="key">
            <td v-for="(value, key2) in values" :key="key2">{{ value }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>-->
		<v-simple-table id="scrollTable">
			<template
			v-if="isDataLoaded"
			v-slot:default
			>
				<thead>
					<tr>
						<th
						v-for="(header, key) in headers"
						:key="key"
						class="font-weight-bold text-center"
						>
							{{ header }}
						</th>
					</tr>
				</thead>

				<tbody>
					<tr
					v-for="(value, key) in infos"
					:key="key"
					>
						<td>{{ value }}</td>

						<td
						v-for="(values, key2) in items"
						:key="key2"
						>
							{{ items[value][key2] }}
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
export default {
	data(){
		return {
			campaign: {},
			dashboard: {},
			cores: [],
			pks: [],
			materials: [],
			sortedCores: [],
			headers: [" "],
			coresLayers: [],
			items: [],
			infos: [
				"Réf",
				"Date",
				"Route",
				"Sens",
				"Voie",
				"PR",
				"Position",
				"Diamètre"
			],
			infosToIndex: {
				Réf: "businessId",
				Date: "createdAt",
				Sens: "direction",
				Voie: "lane",
				PR: "landmark",
				Position: "lateralPosition",
				Diamètre: "diameter"
			},
			mapComponentName: "structural-coring-campaign-map",
			isDataLoaded: false
		};
	},
	methods: {
		exportCsv(){
			this.$api.documents
			.exportStructuralCsv(this.campaign.id)
			.then(documentContent => {
				const url = window.URL.createObjectURL(new Blob([documentContent]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "MAORIE_Modele_import_CSV_carottages");
				link.click();
			});
		},
		addHeaders(){
			this.cores.forEach(e => this.headers.push("C" + e.number));
		},
		addItems(){
			this.infos.forEach(info => {
				this.items[info] = [];
				this.cores.forEach(core => {
					if(info === "Route"){
						this.items[info].push(core.road.name);
						return;
					}
					if(info === "Date"){
						this.items[info].push(
							this.moment(core[this.infosToIndex[info]]).format("Do/MM/YYYY")
						);
						return;
					}
					this.items[info].push(core[this.infosToIndex[info]]);
				});
			});
			this.items.length = this.cores.length;
		}
	},
	mounted(){
		const scrollContainer = document.querySelector(".v-data-table__wrapper");

		scrollContainer.addEventListener("wheel", evt => {
			evt.preventDefault();
			scrollContainer.scrollLeft += evt.deltaY;
		});

		this.$api.campaigns
		.findById(this.$route.params.id)
		.then(data => {
			this.campaign = data;
			this.isDataLoaded = true;
			this.$api.cores.findByCampaign(this.$route.params.id).then(data => {
				this.cores = data;
				this.addHeaders();
				this.addItems();
			});
		});
	}
};
</script>

<style lang="scss" scoped>
th,
td {
  border: solid 1px lightgrey;
  min-width: 180px;
}
th {
  background-color: #2b0803;
  color: white !important;
  font-size: 24px;
}
tr > td:first-child {
  background-color: #ebe7e7;
  font-weight: bold;
}
table {
  border-radius: 4px !important;
}
</style>
