<template>
	<div class="tableContainer">
		<v-expansion-panels
		class="bordered"
		flat
		>
			<v-expansion-panel>
				<v-expansion-panel-header>
					<div class="text-center">
						<h2 class="mb-2 mt-2">
							Résultats HCT
						</h2>
					</div>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-data-table
					class="bordered custom-table"
					flat
					:headers="headers"
					:items="processedHctTableData"
					:items-per-page="-1"
					hide-default-footer
					:sort-by="['coreBusiness', 'coreLayerNumber']"
					:sort-desc="[false, false]"
					:item-class="itemBackground"
					>
						<template
						v-slot:[`item.results.TH.sum`]="{ item }"
						>
							<v-chip
							:class="
								item.results.TH !== null
									? item.results.TH.category === '> 500'
										? 'whiteText'
										: ''
									: 'whiteText'
							"
							:color="getColor(item.results.TH ? item.results.TH.category : null)"
							>
								{{
									getText(item)
								}}
							</v-chip>
						</template>

						<template
						v-slot:[`item.results.TH`]="{ item }"
						>
							<v-chip
							:class="
								item.results.TH !== null
									? item.results.TH.category === '> 500'
										? 'whiteText'
										: ''
									: 'whiteText'
							"
							:color="getColor(item.results.TH ? item.results.TH.category : null)"
							>
								{{
									getValueText(item.results.TH !== null ? item.results.TH.sum : null)
								}}
							</v-chip>
						</template>
					</v-data-table>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
export default {
	name: "HctTable",
	props: {hctTableData: {required: true, type: Array}},
	data(){
		return {
			headers: [
				{
					text: "Carotte (Ref.)",
					align: "center",
					sortable: true,
					value: "core.customerId",
				},
				{
					text: "Réf. bureau d'étude",
					align: "center",
					sortable: true,
					value: "businessOrDisplay",
				},
				{
					text: "Ouvrage",
					align: "center",
					sortable: true,
					value: "core.road.name",
				},
				{
					text: "PR",
					align: "center",
					sortable: true,
					value: "core.landmark",
				},
				{
					text: "Abs",
					align: "center",
					sortable: true,
					value: "core.abscissa",
				},
				{
					text: "Sens",
					align: "center",
					sortable: true,
					value: "core.direction",
				},
				{
					text: "Voie",
					align: "center",
					sortable: true,
					value: "core.lane",
				},
				{
					text: "Latéralisation",
					align: "center",
					sortable: true,
					value: "core.lateralPosition",
				},
				{
					text: "Couche",
					align: "center",
					sortable: true,
					value: "number",
				},
				{
					text: "Matériau",
					align: "center",
					sortable: true,
					value: "materialName",
				},
				{
					text: "Épaisseur",
					align: "center",
					sortable: true,
					value: "thickness",
				},
				{
					text: "Date prélèvement",
					align: "center",
					sortable: true,
					value: "core.extractedAt",
				},
				{
					text: "Date analyse",
					align: "center",
					sortable: true,
					value: "results.TH.receivedDate",
				},
				{
					text: "Groupement",
					align: "center",
					sortable: true,
					value: "providerGroupementNumber"
				},
				{
					text: "Polluants",
					align: "center",
					sortable: true,
					value: "results.TH",
				},
				{
					text: "Totaux C10-C40",
					align: "center",
					sortable: false,
					value: "results.TH.sum"
				},
				{
					text: "Réf. laboratoire",
					align: "center",
					sortable: true,
					value: "results.TH.labRef",
				},
				{
					text: "Réf. MAORIE",
					align: "center",
					sortable: false,
					value: "businessId",
				},
			]
		};
	},
	computed: {
		processedHctTableData(){
			return this.hctTableData.map((item, index, array) => {
				const currentCoreNumber = item.core.number;
				const previousCoreNumber = index > 0 ? array[index - 1].core.number : null;
				const nextCoreNumber = index < array.length - 1 ? array[index + 1].core.number : null;

				const isFirstLayer = index === 0 || previousCoreNumber !== currentCoreNumber;
				const isLastLayer = index === array.length - 1 || nextCoreNumber !== currentCoreNumber;

				return {
					...item,
					isFirstLayer,
					isLastLayer,
				};
			});
		},
	},
	methods: {
		itemBackground(item){
			return {
				"grey lighten-3": item.core.number % 2 === 0,
				white: item.core.number % 2 !== 0,
				"first-layer-border": item.isFirstLayer,
				"last-layer-border": item.isLastLayer,
				"core-border": true,
			};
		},
		getInfos(category){
			switch (category){
				case "[0; 500]":
					return {
						color: "#69b34c",
						text: "[0; 500]",
					};
				case "> 500":
					return {
						color: "#000",
						text: "> 500",
					};
			}
		},
		getColor(item){
			if("< 500" === item && item !== null) return "#69b34c";
			else if("> 500" === item && item !== null) return "#ff0d0d";
			return "#000000";
		},
		getText(item){
			if(null == item.results.TH && item.isThAnalyze) return "En attente de résultat";
			if(null == item.results.TH && !item.isThAnalyze) return "Expertise non demandée";
			let isLessThan500 = false;
			if(item.results.TH.sum <= 500) isLessThan500 = true;
			if(isLessThan500) return "< 500";
			else return "> 500";
		},
		getValueText(item){
			if(item !== null){
				let value = parseInt(item);
				if(value <= 500) return "Non polluants";
				if(value > 500) return "Polluants";
			}
			return "Aucun résultat";

		}
	}
};
</script>

<style lang="scss" scoped>
.tableContainer {
  .bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
  }

  .custom-table {
    /* Style des en-têtes */
    ::v-deep thead th {
      font-weight: bold !important;
      font-size: 18px !important;
      color: black !important;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
    }

    /* Bordure latérale gauche et droite pour toutes les cellules d'une core */
    ::v-deep tbody tr.core-border > td:first-child {
      border-left: 4px solid black !important;
    }

    ::v-deep tbody tr.core-border > td:last-child {
      border-right: 4px solid black !important;
    }

    /* Bordure supérieure pour la première ligne de la core */
    ::v-deep tbody tr.first-layer-border > td {
      border-top: 4px solid black !important;
    }

    /* Bordure inférieure pour la dernière ligne de la core */
    ::v-deep tbody tr.last-layer-border > td {
      border-bottom: 4px solid black !important;
    }

    /* Rayon de bordure pour les coins */
    ::v-deep tbody tr.first-layer-border > td:first-child {
      border-top-left-radius: 4px !important;
    }
    ::v-deep tbody tr.first-layer-border > td:last-child {
      border-top-right-radius: 4px !important;
    }
    ::v-deep tbody tr.last-layer-border > td:first-child {
      border-bottom-left-radius: 4px !important;
    }
    ::v-deep tbody tr.last-layer-border > td:last-child {
      border-bottom-right-radius: 4px !important;
    }
  }
}

.whiteText {
  color: white !important;
}
</style>

