<template>
	<div
	class="coreScheme prevent-select"
	:class="isActiveCore === false ? 'forceGrey' : ''"
	v-show="show"
	>
		<div
		class="coreScheme__number"
		:id="'coreScheme_'+core.id"
		>
			<p
			class="coreScheme__title"
			:class="isActiveCore ? displayClickable ? 'clickableBackground' :'' : ''"
			@click="isActiveCore ? displayClickable ? assignAllLayers() : '' : ''"
			>
				Carotte {{ core.displayName !== '000' && core.displayNamePrefix !== undefined ? core.displayNamePrefix.name.slice(-3)+core.displayName : core.number }}
			</p>

			<p class="coreScheme__HAP">
				Voie : {{ core.lane }}
			</p>

			<span
			class="coreScheme__HAP"
			v-if="isGroupementAvailable && isActiveCore"
			>Groupement HAP : 
				<v-icon
				:disabled="!isGroupementAvailable"
				@click="startSelection($event)"
				>
					mdi-plus-circle-outline
				</v-icon>

				<v-icon
				:disabled="!isGroupementAvailable"
				@click="removeSelection($event)"
				>
					mdi-minus-circle-outline
				</v-icon>
			</span>

			<span
			class="coreScheme__Sent"
			v-if="core.isSent === true"
			>
				Carotte envoyée
			</span>

			<span
			class="coreScheme__Sent"
			v-if="core.status === 'cancelled'"
			>
				Carotte annulée
			</span>
		</div>

		<b
		class="coreScheme__priority"
		:id="'priority_'+core.id"
		@click="togglePriority()"
		v-if="isPriorityAvailable"
		>
			Priorité Eurofins : {{ core.priorityCode }}
		</b>

		<div
		class="coreScheme__layer"
		:style="
			'background-color: ' + (0 === layer.number % 2 ? 'white' : '#ddd')
		"
		v-for="layer in layers"
		:key="core.id + layer.id"
		:id="'Layer_'+layer.id"
		>
			<div
			class="layerName tw-z-[5] tw-relative"
			@click="isActiveCore ? handleLayerClick(layer) : false"
			:class="isActiveCore ? displayClickable ? 'clickableBackgroundLayer' :'' : ''"
			>
				C{{ layer.number }} 
				{{ layer.materialCategory || layer.material ? "-" : "" }} 
				{{ layer.materialCategory && layer.materialCategory.name.includes('(') && layer.materialCategory.name.includes(')') ? layer.materialCategory.name.match(/\(([^)]+)\)/)[1] : "" }} 
				{{ layer.material ? layer.material.name : "" }} - {{ layer.thickness }}mm
				<br/>
				<span v-if="layer.layerName">
					{{ layer.layerName.name }}
				</span>
			</div>


			<div
			class="coreScheme__prestations tw-pt-[5px] tw-pb-[5px]"
			:style="'height:80px'"
			>
				<v-tooltip
				v-for="prestation in layer.prestations"
				:key="prestation.id"
				top
				>
					<template v-slot:activator="{ on, attrs }">
						<v-chip
						@dblclick="removePrestation(prestation, layer)"
						:color="getColor(prestation, layer)"
						class="mb-1 clickablePrestation"
						x-small
						v-bind="attrs"
						v-on="on"
						>
							<b> {{ resolvePrestationType(prestation.prestation) }} :&nbsp</b>

							{{
								prestation.prestation.name
							}}
						</v-chip>
					</template>

					<span>{{ prestation.prestation.name }}</span>
				</v-tooltip>
			</div>
		</div>

		<div
		:id="'Selector_'+layerGroupement.number+'_'+core.id"
		class="selector prevent-select"
		v-for="layerGroupement in layersGroupement"
		style="position: absolute"
		@mousedown="layerGroupement.number === layersGroupement.length ? handleMouseMove(layerGroupement.number) : ''"
		>
			<span
			class="selectorText"
			>
				Groupement n°{{ layerGroupement.number }}
			</span>
		</div>
	</div>
</template>

<script>
import areiaUtils from "@/utils/areia.utils";
import eurofinsUtils from "@/utils/eurofins.utils";

export default {
	props: [
		"value", "prestations", "provider", "layerCoef", "layerPrestations", "campaignStatus", "show"
	],
	data(){
		return {
			core: {
				number: 0,
				priorityCode: "",
				layerAndInterfaces: []
			},
			layers: [],
			coreThickness: 0,
			isSelecting: false,
			startX: 0,
			startY: 0,
			endX: 0,
			endY: 0,
			layersGroupement: [],
			layersHeight: [],
			coreScheme: 0,
			priorityHeight: 0,
			coreSchemeStart: 0,
			displayClickable: false,
			coresContainer: false,
			isGroupementAvailable: false,
			isPriorityAvailable: false,
			styles: [],
			isActiveCore: true
		};
	},
	watch: {
		show(){
			if(this.show === true){
				this.styles = [];
				this.layersGroupement = [];
				//this.filterLayers();
			}
		},
		prestations(){
			if(this.prestations.length >= 1){
				this.displayClickable = true;
			}
			else {
				this.displayClickable = false;
			}
		},
		isGroupementAvailable(){
			this.sortLayersHeight();
		},
		isPriorityAvailable(){
			this.sortLayersHeight();
		},
		provider(){
			if(this.provider.name === "EUROFINS"){
				this.isPriorityAvailable = true;
				if(this.hasAtLeastOnePAH()){
					this.isGroupementAvailable = true;
				}
			}
			else if(this.provider.name === "AREIA"){
				this.isPriorityAvailable = false;
				if(this.hasAtLeastOnePAH()){
					this.isGroupementAvailable = true;
				}
			}
		}
	},
	methods: {
		hasAtLeastOnePAH(){
			let hasPAH = false;
			this.layers.forEach(layer => {
				layer.prestations.forEach(prestation => {
					if(prestation.prestation.type === "PAH"){
						hasPAH = true;
					}
				});
			});

			return hasPAH;
		},
		resetGroupement(){
			this.layersGroupement = [];
			this.addGroupementNumberToLayers();
		},
		startSelection(){
			this.$emit("editGroupement");
			let newNumber = this.layersGroupement.length + 1;
			let width = 0;
			let height = 0;
			let lastLayerId = this.layers.find(e => e.number === this.layers.length).id;
			let lastElementBound = document.getElementById("Layer_" + lastLayerId).getBoundingClientRect();
			if(newNumber === 1){
				this.coreScheme = document.getElementById("coreScheme_" + this.core.id).getBoundingClientRect();
				this.coreSchemeHeight = this.coreScheme.height;
				this.isPriorityAvailable ? this.priorityHeight = document.getElementById("priority_" + this.core.id).getBoundingClientRect().height : "";
				this.coreSchemeStart = this.coreScheme.top;
				this.startX = 0;
				this.startY = this.priorityHeight + this.coreSchemeHeight;
				this.endX = lastElementBound.width;
				this.endY = lastElementBound.bottom;
				width = this.endX - this.startX;
				height =  this.endY - this.startY - this.coreSchemeStart;
			}
			else {
				let positionLastElementOfGroupement = this.layersGroupement[this.layersGroupement.length - 1].bottom;
				if(positionLastElementOfGroupement === lastElementBound.bottom  || newNumber > this.layers.length){
					console.log("Pas de couche après");
					return;
				}
				this.startX = 0;
				this.startY = positionLastElementOfGroupement - this.coreSchemeStart;
				this.endX = lastElementBound.width;
				this.endY = lastElementBound.bottom;
				width = this.endX - this.startX;
				height =  this.endY - this.startY - this.coreScheme.top; //this.endY - positionLastElementOfGroupement;
			}
			this.layersGroupement.push({number: newNumber, bottom: lastElementBound.bottom});
			this.layersGroupement[newNumber - 1].layers = [];
			this.layersHeight.forEach(layer => {
				if(newNumber > 1){
					if(this.layersGroupement[newNumber - 2].bottom < layer.bottom){
						this.layersGroupement[newNumber - 1].layers.push(layer.id);
					}
				}
				else {
					this.layersGroupement[newNumber - 1].layers.push(layer.id);
				}
			});
			this.isSelecting = true;

			setTimeout(() => {
				const selector = document.getElementById("Selector_" + newNumber + "_" + this.core.id);
				const styles = {
					left: `${this.startX}px`,
					top: `${this.startY}px`,
					width: `${width}px`,
					bottom: "0px",
					cursor: "ns-resize"
				};
				Object.assign(selector.style, styles);
			}, 10);


			this.addGroupementNumberToLayers();
		},
		async addSelection(newNumber, tempTabl, index){
			let width = 0;
			let height = 0;
			let lastLayerId = this.layers.find(e => e.number === this.layers.length).id;
			let lastElementBound = document.getElementById("Layer_" + lastLayerId).getBoundingClientRect();
			if(newNumber === 1){
				this.coreScheme = document.getElementById("coreScheme_" + this.core.id).getBoundingClientRect();
				this.coreSchemeHeight = this.coreScheme.height;
				this.isPriorityAvailable ? this.priorityHeight = document.getElementById("priority_" + this.core.id).getBoundingClientRect().height : 0;
				this.coreSchemeStart = this.coreScheme.top;
				this.startX = 0;
				this.startY = this.priorityHeight + this.coreSchemeHeight;
				this.endX = lastElementBound.width;
				this.endY = lastElementBound.bottom;
				width = this.endX - this.startX;
				height =  this.endY - this.startY - this.coreSchemeStart;
			}
			else {
				let positionLastElementOfGroupement = this.layersGroupement[this.layersGroupement.length - 1].bottom;
				if(positionLastElementOfGroupement === lastElementBound.bottom  || newNumber > this.layers.length){
					console.log("Pas de couche après");
					return;
				}
				this.startX = 0;
				this.startY = positionLastElementOfGroupement - this.coreSchemeStart;
				this.endX = lastElementBound.width;
				this.endY = lastElementBound.bottom;
				width = this.endX - this.startX;
				height =  this.endY - this.startY - this.coreScheme.top; //this.endY - positionLastElementOfGroupement;
			}

			let layersFromGroupement = tempTabl.filter(e => e.number === newNumber);
			let lastLayerOfGroupement = layersFromGroupement.reduce(function(prev, current){
				return (prev && prev.bottom > current.bottom) ? prev : current;
			});

			this.layersGroupement.push({number: newNumber, bottom: lastLayerOfGroupement.bottom});
			this.layersGroupement[newNumber - 1].layers = [];
			this.layersHeight.forEach(layer => {
				if(lastLayerOfGroupement.bottom >= layer.bottom){
					if(newNumber >= 2){
						if(layer.bottom > this.layersGroupement[newNumber - 2].bottom){
							this.layersGroupement[newNumber - 1].layers.push(layer.id);
						}
					}
					else {
						this.layersGroupement[newNumber - 1].layers.push(layer.id);
					}
				}
			});
			
			this.styles.push({
				left: `${this.startX}px`,
				top: `${this.startY}px`,
				width: `${width}px`,
				height: `${lastLayerOfGroupement.bottom - this.startY - 103}px`,
				zIndex: 0,
				cursor: "ns-resize"
			});
		},
		removeSelection(){
			this.$emit("editGroupement");
			this.layersGroupement.pop();
			if(this.layersGroupement.length >= 2){
				this.startY = this.layersGroupement[this.layersGroupement.length - 2].bottom - this.coreSchemeStart;
			}
			else {
				this.startY = this.priorityHeight + this.coreSchemeHeight;
			}
			this.addGroupementNumberToLayers();
		},
		handleMouseMove(number){

			this.$emit("editGroupement");
			const selector = document.getElementById("Selector_" + number + "_" + this.core.id);

			let layerHeight;

			const mouseup = (e) => {
				let lastLayer = this.layersHeight.find(layer => layer.bottom > e.y);
				if(this.layersGroupement.length > 1){
					if(e.y < this.layersGroupement[this.layersGroupement.length - 2].bottom){
						lastLayer = this.layersHeight[this.layersHeight.length - 1];
					}
				}
				if(lastLayer === undefined){
					lastLayer = this.layersHeight[this.layersHeight.length - 1];
				}
				layerHeight = lastLayer.bottom - this.startY - 103;
				const styles = {
					height: `${layerHeight}px`
				};
				let lastIndex = this.layersGroupement.findIndex(e => e.number === number);
				this.layersGroupement[lastIndex].bottom = lastLayer.bottom;
				this.layersGroupement[lastIndex].layers = [];
				this.layersHeight.forEach(layer => {
					if(this.layersGroupement.length > 1){
						if(this.layersGroupement[lastIndex - 1].bottom < layer.bottom && layer.bottom <= lastLayer.bottom){
							this.layersGroupement[lastIndex].layers.push(layer.id);
						}
					}
					else {
						if(layer.bottom <= lastLayer.bottom){
							this.layersGroupement[lastIndex].layers.push(layer.id);
						}
					}
				});

				Object.assign(selector.style, styles);
				window.removeEventListener("mousemove", mousemove);
				window.removeEventListener("mouseup", mouseup);
			};

			const mousemove = (e) => {
				let m_pos = e.y - this.startY - this.coreScheme.top;
				let lastLayerBottom = this.layersHeight[this.layersHeight.length - 1].bottom - this.startY - this.coreScheme.top;
				if(m_pos !== 0 && m_pos < lastLayerBottom){
					const styles = {
						height: `${m_pos}px`
					};
					Object.assign(selector.style, styles);
				}
			};

			window.addEventListener("mousemove", mousemove);
      		window.addEventListener("mouseup", mouseup);
		},
		resetSelector(){
			const selector = this.$refs.selector;
			selector.style.width = "0";
			selector.style.height = "0";
		},
		async sortLayersHeight(){
			setTimeout(() => {

				if(this.layersHeight.length !== 0){

					this.layersHeight = [];
					
				}

				this.layers.forEach(layer => {
					let layerElementRec = document.getElementById("Layer_" + layer.id).getBoundingClientRect();
					this.layersHeight.push({
						id: layer.id, number: layer.number, startY: layerElementRec.y, bottom: layerElementRec.bottom
					});
				});
				this.setGroupements();

			}, 10);
		},
		addAllPrestations(){
			if(this.show){
				this.core.layers.forEach(layer => {
					this.addPrestations(
						layer,
						this.prestations.map(p => {
							return {
								prestation: {...p, provider: this.provider},
								layer: {},
								result: []
							};
						})
					);
				});
			}
			if(this.hasAtLeastOnePAH()){
				this.isGroupementAvailable = true;
			}
			else {
				this.isGroupementAvailable = false;
			}
			if(this.provider.name === "EUROFINS"){
				this.isPriorityAvailable = true;
			}
		},
		/** available priority codes are referenced in eurofins edi documentation */
		togglePriority(){
			this.core.priorityCode =
        "STD" === this.core.priorityCode ? "RUSH" : "STD";
			this.emitChange();
		},
		emitChange(){
			let core = this.core;
			core.layers = this.layers;
			this.sortLayersHeight();
			this.$emit("input", core);
		},
		addGroupementNumberToLayers(){
			this.core.layers.forEach((layer, index) => {
				let groupement = this.layersGroupement.find(e => e.layers.find(e2 => e2 === layer.id));
				if(undefined !== groupement){
					layer.layerGroupement = groupement.number;
				}
				else {
					layer.layerGroupement = null;
				}
			});
		},
		handleLayerClick(layer){
			this.addPrestations(
				layer,
				this.prestations.map(p => {
					return {
						prestation: {...p, provider: this.provider},
						layer: {},
						result: []
					};
				})
			);

			let actualLayer = this.layers.find(l => l.id === layer.id);

			let hasPAH = actualLayer.prestations.find(prestation => prestation.prestation.type === "PAH") !== undefined;
			if(hasPAH){
				this.isGroupementAvailable = true;
			}
			else {
				this.isGroupementAvailable = false;
			}
		},
		addPrestations(layer, prestations){
			const layerIndex = this.layers.findIndex(l => l.id === layer.id);

			if(-1 === layerIndex) return;
			let selectedPrestations = prestations.map(p => {
				return {
					...p,
					layer: layer
				};
			});
			let newPrestations = [];
			selectedPrestations.forEach(selectedPrestation => {
				if(selectedPrestation.prestation.type === "ASBESTOS"){
					if(layer.isSentAsbestos){
						return;
					}
				}

				if(selectedPrestation.prestation.type === "PAH"){
					if(layer.isSentPAH){
						return;
					}
				}

				if(selectedPrestation.prestation.type === "TH"){
					if(layer.isSentTH){
						return;
					}
				}
				if(
					layer.prestations.filter(e => {
						return e.prestation.code === selectedPrestation.prestation.code;
					}).length === 0
				){
					newPrestations.push(selectedPrestation);
				}
			});

			layer.prestations = [
				...this.layers[layerIndex].prestations,
				...newPrestations
			];

			this.layers[layerIndex].prestations.prestations = layer.prestations;

			this.emitChange();
		},
		removePrestation(prestation, layer){
			if(prestation.prestation.type === "ASBESTOS"){
				if(layer.isSentAsbestos){
					return;
				}
			}

			if(prestation.prestation.type === "PAH"){
				if(layer.isSentPAH){
					return;
				}
			}

			if(prestation.prestation.type === "TH"){
				if(layer.isSentTH){
					return;
				}
			}
			const layerIndex = this.layers.findIndex(l => l.id === layer.id);

			if(-1 === layerIndex) return;

			const prestationIndex = layer.prestations.findIndex(
				p => p.prestation.id === prestation.prestation.id
			);
			layer.prestations.splice(prestationIndex, 1);
			this.emitChange();

			if(this.hasAtLeastOnePAH()){
				this.isGroupementAvailable = true;
			}
			else {
				this.isGroupementAvailable = false;
				this.layersGroupement = [];
			}
		},
		resolvePrestationType(prestation){
			let res = "";
			switch (prestation.provider.name){
				case "EUROFINS":
					res = this.resolveEUROFINSPrestationType(prestation);
					break;
				default:
					res = this.resolveAREIAPrestationType(prestation);
					break;
			}

			return res;
		},
		resolveEUROFINSPrestationType(prestation){
			if(eurofinsUtils.hasAsbestos([prestation])) return "Am";
			else if(eurofinsUtils.hasPAH([prestation])) return "HAP";
		},
		resolveAREIAPrestationType(prestation){
			if(areiaUtils.hasAsbestos([prestation])) return "Am";
			else if(areiaUtils.hasPAH([prestation])) return "HAP";
			else if(areiaUtils.hasTH([prestation])) return "HCT";
		},
		async filterLayers(){
			if(undefined !== this.$store.state.cores.all[this.core.id]){
				this.layers = this.$store.state.cores.all[this.core.id];
				this.emitChange();
				this.setGroupements();
				return;
			}
			else {
				let finalLayers = [];

				this.$api.coreLayers.get(this.core.id).then(coreLayers => {
					coreLayers.forEach(coreLayer => {
						finalLayers.push({
							...coreLayer,
							prestations: coreLayer.layerPrestations
						});
					});

					finalLayers.sort((a, b) => a.number - b.number);
					this.layers = finalLayers;
					this.emitChange();
					this.setGroupements();
				});
			}
		},
		focusOn(){
			this.$emit("focusOnCore", this.core);
		},
		getColor(prestation, layer){
			if(prestation.prestation.type === "ASBESTOS"){
				if(layer.isSentAsbestos){
					return "grey";
				}
				return "#FF000055";
			}
			else if(prestation.prestation.type === "PAH"){
				if(layer.isSentPAH){
					return "grey";
				}
				return "#BBDBB4";
			}
			else if(prestation.prestation.type === "TH"){
				if(layer.isSentTH){
					return "grey";
				}
				return "#fc9635";
			}
		},
		assignAllLayers(){
			this.layers.forEach(l => this.handleLayerClick(l));
		},
		checkIfGroupementAvailable(){
			this.core.layersAndInterfaces.forEach(layerAndInterface => {
				if(layerAndInterface.type === "layer"){
					layerAndInterface.layerPrestations.forEach(layerPrestation => {
						if(layerPrestation.prestation.provider.name === "AREIA"){
							if(layerPrestation.prestation.type === "PAH"){
								this.isGroupementAvailable = true;
							}
						}
						if(layerPrestation.prestation.provider.name === "EUROFINS"){
							this.isPriorityAvailable = true;
						}
					});
				}
			});
		},
		setGroupements(){
			let tempTabl = [];
			this.layers.forEach(layer => {
				if(layer.providerGroupementNumber !== null && layer.providerGroupementNumber !== undefined){
					if(this.layersHeight.find(e => e.number === layer.number) !== undefined){
						tempTabl.push(
							{
								id: layer.id,
								number: layer.providerGroupementNumber,
								bottom: this.layersHeight.find(e => e.number === layer.number).bottom
							}
						);
					}
				}
			});
			let lastUsedNumber = 0;
			tempTabl.forEach((layer, index) => {
				if(undefined === this.layersGroupement.find(e => e.number === layer.providerGroupementNumber) && lastUsedNumber !== layer.number){
					lastUsedNumber = layer.number;
					this.addSelection(layer.number, tempTabl, index);
				}
			});
			this.$nextTick(() => {
				this.layersGroupement.forEach((groupement, index) => {
					const selector = document.getElementById("Selector_" + groupement.number + "_" + this.core.id);
					Object.assign(selector.style, this.styles[index]);
				});
			});
			if(this.core.layers !== undefined){
				this.addGroupementNumberToLayers();
			}
		}
	},
	/*beforeDestroy(){
		// set le layer dans le store
		if(undefined === this.$store.state.cores.all[this.core.id]){
			this.$store.commit("cores/addCore", {
				core: this.layers,
				coreIdIndex: this.core.id
			});
		}
	},*/
	async mounted(){
		this.core = this.value;
		this.checkIfGroupementAvailable();
		this.filterLayers();
		this.coresContainer = document.querySelector("#cores-container");
		this.$root.$on("addPrestations", this.addAllPrestations);

		if(this.campaignStatus === "results_obtained"){
			this.isActiveCore = false;
		}
	}
};
</script>

<style lang="scss" scoped>
.layerName {
  cursor: pointer;
  text-align: center;
}

.selector {
	position: absolute;
	border: 2px solid black;
	text-align: right;
}

.selectorText {
  	display: flex;
	color: black;
	height: 100%;
	flex-direction: column;
	justify-content: flex-end;
	font-size: 10px;
	text-align: center;
	font-weight: bold;
}

.coreScheme {
	margin: 10px;
    display: flex;
    flex-direction: column;
	border: 1px solid black;
	min-width: 12rem;
	position: relative !important;
	max-width: 12rem;

	&__priority {
		cursor: pointer;
		text-align: center;
	}

	&__title {
		font-size: 18px;
	}

	&__prestations {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__number {
		text-align: center;
		font-weight: bold;
	}

	&__number {
		text-align: center;
		font-weight: bold;
		cursor: pointer;
	}

	&__layer {
		border-top: 1px solid black;
		min-height: 40px;
		padding: 5px;
	}

	&__HAP {
		font-size: 14px;
	}
	
	&__Sent {
		color: red;
	}

	&__priority {
		font-size: 14px;
	}
}

.clickableBackground {
	background-color: #2C0703;
	color: white;

	&:hover {
		background-color: #2C0703cc;
		color: white;
	}
}
.clickableBackgroundLayer {
	background-color: #2C0703;
	color: white;
	border-radius: 4px;
	position: relative;
	z-index: 2;

	&:hover {
		background-color: #2C0703cc;
		color: white;
	}
}

.clickablePrestation {
	z-index: 2;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.v-application p {
	margin-bottom: 0px !important;
}

.forceGrey {
	background-color: #AAAAAA !important;


	> div {
		background-color: #AAAAAA !important;
		> .coreScheme__title {
			cursor: auto;
		}

		> .layerName {
			cursor: auto;
		}
	}
}
</style>
