<template>
	<div>
		<div class="tw-flex tw-flex-row">
			<div class="tw-flex tw-flex-row tw-gap-[10px]">
				<v-select
				:items="['Sens 1', 'Sens 2']"
				v-model="direction"
				label="Choix du sens"
				outlined
				class="tw-w-[300px] tw-flex"
				hide-details
				dense
				/>
  
				<v-select
				:items="isDataLoaded ? transformLanes(direction === 'Sens 1' ? uniqueLanes.direction1 : uniqueLanes.direction2) : []"
				v-model="selectedLane"
				label="Voie"
				outlined
				class="tw-w-[300px] tw-flex"
				hide-details
				dense
				/>
			</div>
			<div class="tw-flex tw-ml-auto tw-items-center">
				<ButtonSlot @click="displayParameters = true">
					Paramètres
				</ButtonSlot>
			</div>
		</div>
  
		<div class="tw-overflow-x-auto tw-overflow-y-hidden tw-w-full">
			<div>
				<v-simple-table
				id="scrollTable"
				class="tw-mt-[10px] tw-w-full"
				style="table-layout: fixed; overflow-x: visible;"
				>
					<WaitingSlot
					v-if="!isDataLoaded"
					class="tw-h-full"
					/>

					<template
					v-if="isDataLoaded"
					v-slot:default
					>
						<thead>
							<tr>
								<th
								rowspan="4"
								class="tw-font-bold tw-text-center tw-w-[100px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
								style="min-width: 100px; max-width: 100px;"
								>
									Carotte
								</th>
        
								<th
								rowspan="1"
								class="tw-font-bold tw-text-center tw-w-[100px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
								style="min-width: 100px; max-width: 100px;"
								>
									Carotte
								</th>
        
								<th
								v-for="(core, index) in filteredCores"
								:key="index+'header'"
								colspan="1"
								class="tw-font-bold tw-text-center"
								style="width: auto; max-width: none; font-size: 20px;"
								>
									{{ core.displayNamePrefix.name + core.displayName }}
								</th>
							</tr>

							<tr>
								<th
								class="tw-font-bold tw-text-center tw-w-[100px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
								style="min-width: 100px; max-width: 100px;"
								>
									PR
								</th>
        
								<th
								v-for="(core, index) in filteredCores"
								:key="index+'pr'"
								class="tw-font-bold tw-text-center"
								style="width: auto; max-width: none;"
								>
									{{ core.landmark + ' + ' + core.abscissa }}
								</th>
							</tr>

							<tr>
								<th
								class="tw-font-bold tw-text-center tw-w-[100px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
								style="min-width: 100px; max-width: 100px;"
								>
									Voie
								</th>
        
								<th
								v-for="(core, index) in filteredCores"
								:key="index+'lane'"
								class="tw-font-bold tw-text-center"
								style="width: auto; max-width: none;"
								>
									{{ core.lane !== null ? core.lane : 'Sans voie' }}
								</th>
							</tr>

							<tr>
								<th
								class="tw-font-bold tw-text-center tw-w-[100px] tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
								style="min-width: 100px; max-width: 100px;"
								>
									Position
								</th>
        
								<th
								v-for="(core, index) in filteredCores"
								:key="index+'position'"
								class="tw-font-bold tw-text-center"
								style="width: auto; max-width: none;"
								>
									{{ $t(core.damage) }}
								</th>
							</tr>
						</thead>
	
						<tbody>
							<tr
							v-for="(layerName, index) in sortedLayerNames"
							:key="index+'layer'"
							>
								<td
								class="tw-text-center"
								style="min-width: 100px; max-width: 100px;"
								/>

								<td
								class="tw-text-center tw-font-bold"
								:style="{ backgroundColor: layerName.color ? layerName.color : 'transparent', minWidth: '100px', maxWidth: '100px' }"
								>
									{{ layerName.name }}
								</td>

								<td
								v-for="(core, coreIndex) in filteredCores"
								:key="coreIndex+'layer-thickness'"
								class="tw-text-center"
								style="width: auto; max-width: none;"
								>
									<span v-if="hasLayerWithName(core, layerName.name)">
										{{ getLayerThickness(core, layerName.name) + ' mm' }}
									</span>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<v-simple-table
				id="scrollTable2"
				class="tw-mt-[10px] tw-w-full"
				style="table-layout: fixed; overflow-x: visible;"
				>
					<WaitingSlot
					v-if="!isDataLoaded"
					class="tw-h-full"
					/>

					<template
					v-if="isDataLoaded"
					v-slot:default
					>
						<tbody>
							<tr>
								<td
								class="tw-text-center"
								style="min-width: 100px; max-width: 100px; width: 100px"
								>
									Epaisseur d'EB (supérieur)
								</td>

								<td
								class="tw-text-center"
								style="min-width: 100px; max-width: 100px; width: 100px"
								>
									<!-- Alignement avec le tableau du dessus -->
								</td>

								<td
								v-for="(core, coreIndex) in filteredCores"
								:key="coreIndex + 'eb-thickness'"
								class="tw-text-center"
								style="width: auto; max-width: none;"
								>
									<v-text-field
									outlined
									dense
									type="text"
									v-model="core.ebThickness"
									hide-details
									class="tw-w-full"
									/>
								</td>
							</tr>

							<tr>
								<td
								class="tw-text-center"
								style="min-width: 100px; max-width: 100px; width: 100px"
								>
									Epaisseur de MTLH
								</td>

								<td
								class="tw-text-center"
								style="min-width: 100px; max-width: 100px; width: 100px"
								>
									<!-- Alignement avec le tableau du dessus -->
								</td>

								<td
								v-for="(core, coreIndex) in filteredCores"
								:key="coreIndex + 'mtlh-thickness'"
								class="tw-text-center"
								style="width: auto; max-width: none;"
								>
									<v-text-field
									outlined
									dense
									type="text"
									v-model="core.mtlhThickness"
									hide-details
									class="tw-w-full"
									/>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

				<ButtonSlot
				v-if="isDataLoaded"
				class="tw-mt-[10px]"
				@click="uploadThickness()"
				>
					Valider les épaisseurs
				</ButtonSlot>

				<div>
					<StackedBarChart
					v-if="isDataLoaded"
					:cores="filteredCores"
					/>
				</div>

				<div>
					<CorePicturesDiv
					v-if="isDataLoaded"
					:cores="filteredCores"
					/>
				</div>
			</div>
		</div>

		<StructuralParametersModal
		v-if="displayParameters"
		:campaign="campaign"
		:uniqueLayerNames="uniqueLayerNames"
		:isOpen="displayParameters"
		@closePopin="displayParameters = false"
		/>
	</div>
</template>

<script>
import StructuralParametersModal from "./StructuralParameters.modal.vue";
import StackedBarChart from "./StackedBarChart.vue";
import CorePicturesDiv from "./CorePicturesDiv.vue";

export default {
	components: {
		StructuralParametersModal,
		StackedBarChart,
		CorePicturesDiv
	},
	props: ["campaign"],
	data(){
		return {
			cores: [],
			isDataLoaded: false,
			uniqueLanes: [],
			uniqueLayerNames: [],
			direction: "Sens 1",
			selectedLane: null,
			headers: [
				"Carotte",
				"PR",
				"Voie",
				"Position"
			],
			displayParameters: false,
			maxThickness: 0
		};
	},
	computed: {
		filteredCores(){
			const direction = this.direction === "Sens 1" ? 1 : 2;

			return this.cores.filter(core => {
				const isDirectionMatch = core.direction === direction;
				const isLaneMatch = this.selectedLane === null || core.lane === this.selectedLane || (core.lane === null && this.selectedLane === "Sans voie");
				const isStatusValid = core.status !== "empty" && core.status !== "waiting";
    
				return isDirectionMatch && isLaneMatch && isStatusValid;
			});
		},

		sortedLayerNames(){
			const filteredLayerNames = this.uniqueLayerNames.filter(layerName => {
				return this.filteredCores.some(core => 
					core.layersAndInterfaces.filter(e => e.type === "layer").some(layer => 
						layer.layerName &&
						layer.layerName.name === layerName.name &&
						this.matchesDirectionAndLane(core)
					)
				);
			});

			return filteredLayerNames.sort((a, b) => {
				const aIsMTLH = a.materialCategory && a.materialCategory.initialism === "MTLH";
				const bIsMTLH = b.materialCategory && b.materialCategory.initialism === "MTLH";

				if(aIsMTLH && !bIsMTLH) return 1;
				if(!aIsMTLH && bIsMTLH) return -1;

				if(a.number === null) return 1;
				if(b.number === null) return -1;
				return a.number - b.number;
			});
		}
	},
	methods: {
		uploadThickness(){
			this.filteredCores.forEach(core => {
				if(core.ebThickness !== null || core.mtlhThickness !== null){
					this.$api.cores.patch({ebThickness: core.ebThickness, id: core.id, mtlhThickness: core.mtlhThickness});
				}
			});
		},
		matchesDirectionAndLane(core){
			const directionMatch = this.direction === "Sens 1" ? core.direction === 1 : core.direction === 2;
			const laneMatch = this.selectedLane === null || this.selectedLane === "Sans voie" || core.lane === this.selectedLane;
			return directionMatch && laneMatch;
		},
		setNumberOfLayers(){
			let max = 0;
			let numberOfLayers;
			this.cores.forEach(core =>  {
				numberOfLayers = core.layersAndInterfaces.filter(e => e.type === "layer").length;
				if(max < numberOfLayers){
					max = numberOfLayers;
				}
			});
			this.layersByCores = max + 1;
		},
		extractUniqueLanes(){
			const lanesByDirection = {
				direction1: new Set(),
				direction2: new Set()
			};
    
			this.cores.forEach(core => {
				if(core.direction === 1){
					lanesByDirection.direction1.add(core.lane);
				}
				else if(core.direction === 2){
					lanesByDirection.direction2.add(core.lane);
				}
			});

			return {
				direction1: Array.from(lanesByDirection.direction1),
				direction2: Array.from(lanesByDirection.direction2)
			};
		},
		extractUniqueLayerNames(){
			const uniqueLayerNames = new Map();
    
			this.cores.forEach(core => {
				core.layersAndInterfaces.forEach(layer => {
					if(layer.layerName && layer.layerName.id && layer.layerName.name){
						if(!uniqueLayerNames.has(layer.layerName.id)){
							uniqueLayerNames.set(layer.layerName.id, layer.layerName);
						}
					}
				});
			});

			return Array.from(uniqueLayerNames.values());
		},
		transformLanes(lanes){
			return lanes.map(lane => lane === null ? "Sans voie" : lane);
		},
		hasLayerWithName(core, layerName){
			return core.layersAndInterfaces.some(layer => layer.layerName && layer.layerName.name === layerName);
		},
		getLayerThickness(core, layerName){
			const layer = core.layersAndInterfaces.find(layer => layer.layerName && layer.layerName.name === layerName);
			return layer ? layer.thickness : null;
		},
		getBarStyle(core, layerName, layerIndex){
			const thickness = this.getLayerThickness(core, layerName) || 0;
			const totalHeight = this.maxThickness;
			const percentageHeight = (thickness / totalHeight) * 100;
			const offset = this.getAccumulatedHeight(core, layerIndex);

			return {
				backgroundColor: this.getLayerColor(layerName),
				height: `${percentageHeight}%`,
				bottom: `${offset}%`,
				position: "absolute",
				width: "100%"
			};
		},
		getAccumulatedHeight(core, layerIndex){
			return this.sortedLayerNames.slice(0, layerIndex).reduce((acc, layer) => {
				const thickness = this.getLayerThickness(core, layer.name) || 0;
				return acc + (thickness / this.maxThickness) * 100;
			}, 0);
		},
		getLayerColor(layerName){
			const layer = this.uniqueLayerNames.find(l => l.name === layerName);
			return layer && layer.color ? layer.color : "#ccc";
		},
		setMaxThickness(){
			this.maxThickness = Math.max(...this.cores.map(core => 
				core.layersAndInterfaces.reduce((acc, layer) => acc + (layer.thickness || 0), 0)
			));
		},
	},
	mounted(){
		this.$api.cores.findByCampaign(this.campaign.id, true).then(data => {
			this.cores = data;
			this.setNumberOfLayers();
			this.uniqueLanes = this.extractUniqueLanes();
			this.selectedLane = this.uniqueLanes.direction1[0];
			this.uniqueLayerNames = this.extractUniqueLayerNames();
			this.isDataLoaded = true;
	  });
	}
};
</script>

<style scoped>
.bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
}
th,
td {
  border: solid 1px lightgrey;
  min-width: 180px;
}
th {
  background-color: #2b0803;
  color: white !important;
  font-size: 24px;
}
tr > td:first-child {
  background-color: #ebe7e7;
  font-weight: bold;
}
table {
  border-radius: 4px !important;
  table-layout: fixed;
  width: 100%;
}

</style>
