<template>
	<div class="tableContainer">
		<v-expansion-panels
		class="bordered"
		flat
		>
			<v-expansion-panel>
				<v-expansion-panel-header>
					<div class="text-center">
						<h2 class="mb-2 mt-2">
							Résultats HAP
						</h2>
					</div>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<div class="tw-flex tw-justify-center tw-w-[100%]">
						<ButtonSlot
						class="tw-flex tw-w-[300px] tw-mb-[10px]"
						@click="(e) => {
							displayDetails ? removeHeaders() : addHeaders()}"
						>
							{{ displayDetails ? 'Masquer le détail' : 'Afficher le détail' }}
						</ButtonSlot>
					</div>
					<v-data-table
					class="bordered custom-table"
					flat
					:headers="headers"
					:items="processedPahTableData"
					:items-per-page="-1"
					hide-default-footer
					:sort-by="['businessId', 'coreLayerNumber']"
					:sort-desc="[false, false]"
					:item-class="itemBackground"
					>
						<template v-slot:[`item.results.PAH.sum`]="{ item }">
							<v-chip
							:class="
								item.results.PAH !== null
									? item.results.PAH.category === '> 1000'
										? 'whiteText'
										: ''
									: 'whiteText'
							"
							:color="
								item.results.PAH !== null
									? getInfos(item.results.PAH.category).color
									: '#333'
							"
							>
								{{
									getText(item.results.PAH, item.isPahAnalyze)
								}}
							</v-chip>
						</template>
						<template v-slot:[`item.results.PAH`]="{ item }">
							<!-- Insérez ici votre logique pour afficher un texte en fonction de la valeur "item.results.PAH.sum" pour la colonne "Déchets" -->
							<v-chip
							:class="
								item.results.PAH !== null
									? item.results.PAH.category === '> 1000'
										? 'whiteText'
										: ''
									: 'whiteText'
							"
							:color="
								item.results.PAH !== null
									? getInfos(item.results.PAH.category).color
									: '#333'
							"
							>
								{{ getValueText(item.results.PAH !== null ? item.results.PAH.sum : null) }}
							</v-chip>
						</template>
						<!-- <template
              v-slot:[`item.results.PAH.computed.PAHSumWithLQ`]="{ item }"
            >
              <v-chip
                :class="
                  item.results.PAH.computed.category === '> 1000'
                    ? 'whiteText'
                    : ''
                "
                :color="getInfos(item.results.PAH.computed.category).color"
              >
                {{ item.results.PAH.computed.PAHSumWithLQ.text }}
              </v-chip>
            </template> -->
					</v-data-table>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
export default {
	name: "HapTable",
	props: {pahTableData: {required: true, type: Array}},
	data(){
		return {
			displayDetails: false,
			headers: [],
			baseHeaders: [
				{
					text: "N° carotte",
					align: "center",
					sortable: true,
					value: "core.number",
				},
				{
					text: "Carotte (Ref.)",
					align: "center",
					sortable: true,
					value: "core.customerId",
				},
				{
					text: "Réf. bureau d'étude",
					align: "center",
					sortable: true,
					value: "businessOrDisplay",
					width: "100px"
				},
				{
					text: "Ouvrage",
					align: "center",
					sortable: true,
					value: "core.road.name",
				},
				{
					text: "PR",
					align: "center",
					sortable: true,
					value: "core.landmark",
				},
				{
					text: "Abs",
					align: "center",
					sortable: true,
					value: "core.abscissa",
				},
				{
					text: "Sens",
					align: "center",
					sortable: true,
					value: "core.direction",
				},
				{
					text: "Voie",
					align: "center",
					sortable: true,
					value: "core.lane",
				},
				{
					text: "Latéralisation",
					align: "center",
					sortable: true,
					value: "core.lateralPosition",
				},
				{
					text: "Couche",
					align: "center",
					sortable: true,
					value: "number",
				},
				{
					text: "Matériau",
					align: "center",
					sortable: true,
					value: "materialName",
				},
				{
					text: "Épaisseur",
					align: "center",
					sortable: true,
					value: "thickness",
				},
				{
					text: "Date prélèvement",
					align: "center",
					sortable: true,
					value: "core.extractedAt",
				},
				{
					text: "Date analyse",
					align: "center",
					sortable: true,
					value: "results.PAH.receivedDate",
				},
				{
					text: "Groupement",
					align: "center",
					sortable: true,
					value: "providerGroupementNumber"
				},
				{
					text: "Déchets",
					align: "center",
					sortable: true,
					value: "results.PAH",
				},
				{
					text: "Somme des HAP (excluant LQ)",
					align: "center",
					sortable: false,
					value: "results.PAH.sum",
					width: "150px",
				},
				{
					text: "Réf. laboratoire",
					align: "center",
					sortable: true,
					value: "results.PAH.labRef",
					width: "150px"
				},
				{
					text: "Réf. MAORIE",
					align: "center",
					sortable: false,
					value: "businessId",
					width: "200px"
				},
			],
		};
	},
	computed: {
		processedPahTableData(){
			return this.pahTableData.map((item, index, array) => {
				const currentCoreNumber = item.core.number;
				const previousCoreNumber = index > 0 ? array[index - 1].core.number : null;
				const nextCoreNumber = index < array.length - 1 ? array[index + 1].core.number : null;

				const isFirstLayer = index === 0 || previousCoreNumber !== currentCoreNumber;
				const isLastLayer = index === array.length - 1 || nextCoreNumber !== currentCoreNumber;

				return {
					...item,
					isFirstLayer: isFirstLayer,
					isLastLayer: isLastLayer,
				};
			});
		},
	},
	methods: {
		itemBackground(item){
			const isEven = item.core.number % 2 === 0;
			return {
				"grey lighten-3": isEven,
				white: !isEven,
				"first-layer-border": item.isFirstLayer,
				"last-layer-border": item.isLastLayer,
				"core-border": true,
			};
		},
		addHeaders(){
			this.displayDetails = true;
			this.headers = this.baseHeaders;
			const items = this.pahTableData;

			for(let i = 0; i < items.length; i++){
				const item = items[i];

				if(null === item.results.PAH) continue;

				const composition = item.results.PAH.composition;

				for(const [elementName] of Object.entries(composition)){
					this.headers.push({
						text: elementName,
						align: "center",
						sortable: false,
						value: "results.PAH.composition." + elementName,
					});
				}
				return;
			}
		},
		removeHeaders(){
			this.displayDetails = false;
			const items = this.pahTableData;
			for(let i = 0; i < items.length; i++){
				const item = items[i];

				if(null === item.results.PAH) continue;

				const composition = item.results.PAH.composition;

				for(const [elementName] of Object.entries(composition)){
					this.headers.pop();
				}
				return;
			}
			this.headers = [...this.baseHeaders];
		},
		getInfos(category){
			switch (category){
				case "[0; 50]":
					return {
						color: "#69b34c",
						text: "[0; 50]",
					};
				case "]50; 500]":
					return {
						color: "#ff8e15",
						text: "]50; 500]",
					};
				case "]500; 1000]":
					return {
						color: "#ff0d0d",
						text: "]500; 1000]",
					};
				case "> 1000":
					return {
						color: "#000",
						text: "> 1000",
					};
			}
		},
		getColor(item){
			let value = item.replace("< ", "");
			value = Number(value.replace(",", "."));
			if(value <= 50) return "#69b34c";
			if(value <= 500) return "#ff8e15";

			return value < 1000 ? "#ff0d0d" : "#000000";
		},
		getText(pahResult, isPahAnalyze){
			if(null == pahResult && isPahAnalyze) return "En attente de résultat";
			if(null == pahResult && !isPahAnalyze) return "Expertise non demandée";
			else return pahResult.textValue;
		},
		getValueText(item){
			if(item !== null){
				let value = parseInt(item);
				if(value <= 50) return "Inertes : recyclable à chaud ou à froid";
				if(value <= 500) return "Non dangereux : recyclable à froid";

				return value > 500 ? "Dangereux : non recyclable" : "Aucun résultat";
			}
			return "Aucun résultat";

		}
	},
	mounted(){
		this.headers = this.baseHeaders;
	},
};
</script>

<style lang="scss" scoped>
.tableContainer {
  .bordered {
    border: thin solid rgba(0, 0, 0, 0.12);
  }

  .custom-table {
    /* Style des en-têtes */
    ::v-deep thead th {
      font-weight: bold !important;
      font-size: 18px !important;
      color: black !important;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
    }

    /* Bordure latérale gauche et droite pour toutes les cellules d'une core */
    ::v-deep tbody tr.core-border > td:first-child {
      border-left: 4px solid black !important;
    }

    ::v-deep tbody tr.core-border > td:last-child {
      border-right: 4px solid black !important;
    }

    /* Bordure supérieure pour la première ligne de la core */
    ::v-deep tbody tr.first-layer-border > td {
      border-top: 4px solid black !important;
    }

    /* Bordure inférieure pour la dernière ligne de la core */
    ::v-deep tbody tr.last-layer-border > td {
      border-bottom: 4px solid black !important;
    }

    /* Rayon de bordure pour les coins */
    ::v-deep tbody tr.first-layer-border > td:first-child {
      border-top-left-radius: 4px !important;
    }
    ::v-deep tbody tr.first-layer-border > td:last-child {
      border-top-right-radius: 4px !important;
    }
    ::v-deep tbody tr.last-layer-border > td:first-child {
      border-bottom-left-radius: 4px !important;
    }
    ::v-deep tbody tr.last-layer-border > td:last-child {
      border-bottom-right-radius: 4px !important;
    }
  }
}

.whiteText {
  color: white !important;
}
</style>
